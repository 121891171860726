* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

// left

.left_container {
  width: 50%;
}

.card_h4 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #344767;
}

.card_p {
  line-height: 1.625;
  font-weight: 400;
  font-size: 1rem;
  color: #67748e;
  margin-top: 0;
  padding-bottom: 2rem;
}

.form,
label {
  font-size: 0.75rem;
  font-weight: 700;
  color: #344767;
  margin-left: 0.25rem;
}

.input_label {
  margin-bottom: 1rem;
}

.form_control {
  border: 1px solid #d2d6da;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

::placeholder {
  color: #d2d6da;
}

.form_check_label {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #344767;
  margin-left: 0.25rem;
}

// Hide default checkmark
.form_check_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

//Terms and conditions text
.form_check_label {
  position: relative;
  padding-left: 2rem;
  padding-top: 6px;
}

.form_check_label a {
  text-decoration: none;
  font-weight: 700;
  color: #344767;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #cbd3da;
  border-radius: 0.35rem;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.25rem;
  color: #344767;
  transition: background-color 0.25s ease, border-color 0.25s ease,
    background-position 0.15s ease-in-out, opacity 0.15s ease-out,
    box-shadow 0.15s ease-in-out;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* When the checkbox is checked, add a blue background */
.form_check_label input:checked ~ .checkmark {
  background-color: #344767;
}

/* Show the checkmark when checked */
.form_check_label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form_check_label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sign_btn {
  color: #fff;
  border: 0;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  letter-spacing: -0.025rem;
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  background-size: 150%;
  background-position-x: 25%;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 1.5rem;
  width: 100%;
  font-weight: 700;
  line-height: 1.4;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in;
}

.footer_p {
  padding-top: 1rem;
  line-height: 1.625;
  font-weight: 400;
  font-size: 1rem;
  color: #67748e;
  margin-top: 0;
  padding-bottom: 2rem;
  text-align: center;
}

.footer_p a {
  text-decoration: none;
  font-weight: 400;
  color: #7928ca;
  text-decoration: none;
}

// right

.right_container {
  
  width: 50%;
  overflow-y: hidden;
  height: calc(100vh - 2rem);
  min-height: 400px;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  border-radius: 0.75rem;
  margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 400px;
}

.lines {
  width: 100%;
  height: 100%;
  min-height: 400px;

}

.lines img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.rocketimg {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  padding-left: 6rem;
  padding-right: 6rem;
}

.rocketimg img {
  width: 100%;
  max-width: 500px;
}

.right_h4 {
  color: #fff;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375;
}

.right_p {
  color: #fff;
  line-height: 1.625;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media screen and (max-height: 530px) {
  .right_h4 {
    margin-top: 1rem;
  }
  
  .right_p {
    font-size: 12px;
  }

}
@media screen and (max-width: 960px) {
  .right_container {
    display: none;
  }

  .left_container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // min-width: 600px;
    // margin: auto;
  }

  .main {
    width: 100%;
  }
}
