.sign_btn {
    color: #fff;
    border: 0;
    background-image: linear-gradient(310deg,#7928ca,#ff0080);
    letter-spacing: -.025rem;
    box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
    background-size: 150%;
    background-position-x: 25%;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 1.5rem;
    // width: 100%;
    font-weight: 700;
    line-height: 1.4;
    padding: 0.75rem 1.5rem;
    font-size: .75rem;
    border-radius: 0.5rem;
    transition: all .15s ease-in;
    cursor: pointer;

  }
   
.err_btn {
    color: #fff;
    border: 0;
    background-image: linear-gradient(310deg,#7928ca,#ff0080);
    letter-spacing: -.025rem;
    box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
    background-size: 150%;
    background-position-x: 25%;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 1.5rem;
    width: 20%;
    font-weight: 700;
    line-height: 1.4;
    padding: 0.75rem 1.5rem;
    font-size: .75rem;
    border-radius: 0.5rem;
    transition: all .15s ease-in;
    cursor: pointer;

  }