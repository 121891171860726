.Toastify__toast--error {
  background: #ffeeef;
  color: #333333;
}

.Toastify__toast--success {
  background: #f0faf2;
  color: #333333;
}

.Toastify__toast--info {
  background: #e0f9ff;
  color: #333333;
}

.Toastify__toast--warning {
  background: #faf6ce;
  color: #e78326;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  line-height: 20px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  margin-left: 6px;
  z-index: 100000;
  border-radius: 6px;
  font-size: 14px;
  padding-right: 10px;
  min-height: none;
}

.Toastify__toast {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}